import { Box, Container, Grid, Typography } from "@mui/material";
import { WebsiteContentObject } from "./WebsiteContentObject";
import { Link, useParams } from "react-router-dom";

const root = WebsiteContentObject.customerSlide;

const Customers = () => {
  let { userId } = useParams();
  const selectedCustomer = root.content.filter(
    (customer) => customer.param === userId
  );
  return (
    <div className="innerPage">
      <Box className="bannerCustomer">
        {selectedCustomer.map((item, index) => (
          <img
            src={item.image}
            alt="banner"
            className="imageBanner"
            key={index.toString()}
          />
        ))}

        {/*<h1>{root.title.title}</h1>*/}
        <Box className="logoList">
          {root.content.map((item, index) => (
            <Link to={item.link} key={index.toString()}>
              <img
                src={item.logo}
                alt={item.title}
                className={userId === item.param ? "active" : ""}
              />
            </Link>
          ))}
        </Box>
      </Box>
      <Container fixed>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={9}>
            {selectedCustomer.map((item, index) => (
              <Box className="customerContent" key={index.toString()}>
                <img src={item.logo} alt="banner" className="imageBanner" />
                <Typography variant="h4">{item.title}</Typography>
                {item.paragraph.map((item, index) => (
                  <p
                    key={index.toString()}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Customers;
