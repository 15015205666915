import { Fragment } from "react";
import { Container, Grid } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <section className="policyTerm">
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={9}>
              <h1>Privacy Policy</h1>
              <p>
                Vollee is a proprietary software product owned by Tabtu Corp.
                All rights and interests in and to the Vollee software and any
                associated intellectual property are the exclusive property of
                Tabtu Corp.
              </p>
              <p>
                Tabtu Corp (“*,” “we,” “us”, “our”) is concerned about online
                privacy issues and wants you to be familiar with how we collect,
                use and disclose your Personally Identifiable Information (as
                defined below). This Privacy Policy (the “Policy”) describes our
                practices in connection with Personally Identifiable Information
                that we collect through (1) our web sites (the “Sites”) and (2)
                our online software or other products and services (the
                “Services”). This Policy does not address our practices
                regarding information that we collect by any means other than
                online collection through the Sites or Services.
              </p>
              <p>
                By using the Sites and/or Services, you agree to the terms and
                conditions of this Policy. If you do not agree to the terms and
                conditions of this Policy, please do not use the Sites or
                Services. We reserve the right to change this Policy without
                prior notice. You can determine when this Policy was last
                revised by referring to the “LAST UPDATED” legend at the bottom
                of this Policy. Any changes to our Policy will become effective
                upon our posting of the revised Policy on the Sites. Use of the
                Sites or Services following such changes constitutes your
                acceptance of the revised Policy then in effect.
              </p>
              <h3>
                1. What Types of Information do we Collect Through the Sites?
              </h3>
              <p>
                We gather three basic types of information through the Sites:
                “Personally Identifiable Information” (or “PII”),
                “Non-Personally Identifiable Information” (or “Non-PII”) and “IP
                Addresses”. Personally Identifiable Information is information
                that identifies you as an individual. Non-Personally
                Identifiable Information is aggregated information, demographic
                information and any other information that does not reveal your
                specific identity. An IP Address is the Internet Protocol
                address of the computer that you are using.
              </p>
              <p>
                <b>a. Personally Identifiable Information:</b> You do not have
                to provide PII to use the Sites. However, in order for you to
                take advantage of particular opportunities provided through the
                Sites (for example, to sign up for a free trial or request more
                product information), we may require that you furnish PII. We
                collect PII from you through the Sites, such as your name,
                postal address, company, telephone number or e-mail address,
                only when you voluntarily provide it to us.
              </p>
              <p>
                <b>b. Non-Personally Identifiable Information:</b> When you
                visit and interact with the Sites, * and third parties with whom
                * has contracted to provide services to * may collect Non-PII.
                Non-PII is generally collected through the Sites from four
                different sources: environmental variables, cookies, pixel tags
                and other similar technologies and information that you
                voluntarily provide.
              </p>
              <p>
                <b>i. Environmental Variables:</b>We and our service providers
                may also collect certain environmental variables, such as your
                MAC address, physical or virtual location, computer type
                (Windows or Macintosh), screen resolution, OS version, Internet
                browser, and Internet browser version. These environmental
                variables are collected by most browsers, and can be used to
                optimize your experience on the Sites.
              </p>
              <p>
                <b>ii. Cookies:</b> We and our service providers may use
                “cookies” on the Sites. Cookies are data that a web server
                transfers to an individual’s computer for recordkeeping
                purposes. We use cookies and other technologies to better serve
                with more tailored information and facilitate your ongoing
                access to and use of our Sites. If you do not want information
                collected through the use of cookies, there is a simple
                procedure in most browsers that allows you to automatically
                decline cookies, or to be given the choice of declining or
                accepting the transfer of a particular cookie, or cookies from a
                particular web site, to your computer.
              </p>
              <p>
                <b>iii. Pixel Tags:</b> We and our service providers may also
                use so-called “pixel tags,” “web beacons,” “clear GIFs” or
                similar means (collectively, “Pixel Tags”) in connection with
                some Site pages and HTML-formatted e-mail messages to, among
                other things, compile aggregate statistics about Site usage and
                response rates. A Pixel Tag is an electronic image, often a
                single pixel (1×1), that is ordinarily not visible to Site
                visitors and may be associated with Cookies on the visitors’
                hard drives. Pixel Tags allow us to count users who have visited
                certain pages of the Sites, to deliver branded services, and to
                help determine the effectiveness of promotional or advertising
                campaigns. When used in HTML-formatted e-mail messages, Pixel
                Tags can tell the sender whether and when the e-mail has been
                opened.
              </p>
              <p>
                <b>iv. Information That You Voluntarily Provide:</b> * also
                collects Non-PII (e.g., your title, your employer, your
                geographic location, etc.) when you voluntarily provide such
                information to us. When such information is not combined with
                any PII, such information is considered to be Non-PII, as it
                does not personally identify you or any other user.
                Additionally, we may aggregate PII in a manner such that the
                end-product does not personally identify you or any other user
                of the Sites, for example, by using PII to calculate the
                percentage of users who live in a particular region. Such
                aggregate information is considered Non-PII for purposes of this
                Policy.
              </p>
              <p>
                <b>c. IP Addresses:</b> When you visit and interact with the
                Sites, * and third parties with whom * has contracted to provide
                services to * may collect IP Addresses. Your Internet Protocol
                (IP) address is a number that is automatically assigned to the
                computer that you are using by your Internet Service Provider
                (ISP). This number is identified and logged automatically in our
                server log files whenever you visit the Sites, along with the
                time(s) of your visit(s) and the page(s) that you visited.
              </p>
              <h3>
                2. What Types of Information do we Collect Through the Services?
              </h3>
              <p>
                When you purchase or subscribe to a Service through one of our
                Sites, we may collect PII, Non-Personally Identifiable
                Information, and IP Addresses through the Site as described in
                Section 1. Most of our Services do not collect any PII,
                Non-Personally Identifiable Information or IP Addresses directly
                from users, independently of their use of the Sites. However, a
                few of our Services may involve the collection of certain types
                of user information.
              </p>
              <h3>3. How Does * Use Information Collected From Me?</h3>
              <p>
                * gathers three basic types of information through the Sites:
                “Personally Identifiable Information” (or “PII”),
                “Non-Personally Identifiable Information” (or “Non-PII”) and “IP
                Addresses” as described in Section 1.
              </p>
              <p>
                <b>a. Personally Identifiable Information:</b> We may use PII
                about you in the following ways;
              </p>
              <p>
                <b>i. Fulfillment of Requests:</b> * may use PII about you to
                fulfill the purpose for which such PII was provided (e.g., to
                send product information to you or fulfill your purchase).
              </p>
              <p>
                <b>ii. Administrative Communications:</b> From time to time, in
                our sole discretion, we may use PII about you to send to you
                important information regarding the Sites or Services, changes
                to our terms, conditions, and policies or other administrative
                information. Because this information may be important to your
                use of the Sites or Services, you may not opt-out of receiving
                such communications.
              </p>
              <p>
                <b>ii. Administrative Communications:</b> From time to time, in
                our sole discretion, we may use PII about you to send to you
                important information regarding the Sites or Services, changes
                to our terms, conditions, and policies or other administrative
                information. Because this information may be important to your
                use of the Sites or Services, you may not opt-out of receiving
                such communications.
              </p>
              <p>
                <b>iii. Other Communications:</b> From time to time, we may use
                PII about you to inform you of products, programs, services and
                promotions that we believe may be of interest to you. If you
                would prefer that we do not send electronic marketing messages
                to you, please see the “Opt-Out” section below.
              </p>
              <p>
                <b>iv. Promotions:</b> We may operate sweepstakes, contests and
                similar promotions (collectively, “Promotions”) through the
                Sites. We typically ask you for certain PII when you enter and,
                if applicable, win a Promotion. You should carefully review the
                rules, if any, of each Promotion in which you participate
                through the Sites, as they may contain additional important
                information about *’s use of PII about you. To the extent that
                the terms and conditions of such rules concerning the treatment
                of PII about you conflict with this Policy, the terms and
                conditions of such rules shall control.
              </p>
              <p>
                <b>v. Internal Business Purposes:</b> We may also use PII about
                you for our internal business purposes, such as data analysis,
                audits and so forth.
              </p>
              <p>
                <b>b. Non-Personally Identifiable Information.</b> Because
                Non-PII does not personally identify you, we may use such
                information for any purpose. In addition, we reserve the right
                to share such Non PII, which does not personally identify you,
                with third parties, for any purpose. In some instances, we may
                combine Non-PII with PII (such as embedding e-mail addresses in
                cookies). If we do combine any Non-PII with PII, the combined
                information will be treated by us as PII hereunder as long as it
                is so combined (see Section 1(b) iv above).
              </p>
              <p>
                <b>c. IP Addresses.</b> We use your IP address, and the IP
                addresses of all users, for all purposes listed in Section 3(a),
                as well as for purposes such as calculating Site usage levels,
                helping diagnose server problems, providing better service to
                users of the Sites (such as listing prices in local currency or
                displaying country-specific or currency-specific offers, based
                on geographic region), customizing the Sites based on your
                preferences, compiling and analyzing statistics and trends, and
                otherwise administering and improving the Sites for your use.
                Collecting IP addresses is standard practice on the Internet and
                is done automatically by many web sites. Please note that we
                treat IP Addresses, server log files and related information as
                Non-PII, unless we are required to do otherwise under applicable
                law.
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fragment>
  );
};

export default PrivacyPolicy;
