import React from "react";
import { Box, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        paddingTop: "50px",
        backgroundColor: "#f5f5f5"
      }}
    >
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <h1 style={{ fontSize: "4rem", margin: "0" }}>404</h1>
        <p style={{ fontSize: "1.5rem", margin: "20px 0" }}>Page Not Found</p>
        <Button
          component={Link}
          to="/"
          variant="contained"
          sx={{
            backgroundColor: "#9cdbfc",
            color: "black",
            "&:hover": {
              backgroundColor: "#0c2331",
              color: "white"
            }
          }}
        >
          Go Back Home
        </Button>
      </Container>
    </Box>
  );
};

export default NotFound;
