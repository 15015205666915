import "uikit/dist/js/uikit.min.js";

import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import UIkit from "uikit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// import slideOpen from "../../assets/images/slideEvent.png";
// import slideClose from "../../assets/images/slideEvent2.png";

import {
  HiOutlineArrowRightCircle,
  HiOutlineArrowLeftCircle
} from "react-icons/hi2";

const HomeSlider = ({ sliderContent }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  // const [slidePanel, setSlidePanel] = useState(false);

  useEffect(() => {
    // Get the slider instance
    const sliderElement = document.querySelector("[uk-slideshow]");
    const slideshow = UIkit.slideshow(sliderElement);

    // Play the slider when mounted
    slideshow.startAutoplay();

    // Clean up the autoplay when the component unmounts
    return () => {
      slideshow.stopAutoplay();
    };
  }, []);

  // const slidePanelHandler = () => {
  //   setSlidePanel(!slidePanel);
  // };

  const toggleAutoplay = () => {
    const sliderElement = document.querySelector("[uk-slideshow]");
    const slideshow = UIkit.slideshow(sliderElement);

    if (isPlaying) {
      slideshow.stopAutoplay();
    } else {
      slideshow.startAutoplay();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light ukMainSlider"
      uk-slideshow="animation: push;"
    >
      <div className="uk-slideshow-items">
        {sliderContent.map((item, index) => (
          <div key={index}>
            <div className="videoOverlay"></div>
            <div
              className="uk-position-cover"
              uk-slideshow-parallax="scale: 1.2,1.2,1"
            >
              {item.image ? (
                <img src={item.image} alt="banner" />
              ) : (
                <ReactPlayer
                  url={item.video}
                  muted={true}
                  playing={true}
                  loop
                  width="100%"
                  height="100%"
                />
              )}
            </div>
            <div
              className="uk-position-cover"
              uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"
            ></div>
            <div className="uk-position-bottom-left uk-position-medium ukSlideContent">
              {item.link ? (
                <div uk-slideshow-parallax="scale: 1,1,0.8">
                  <span className="bgEvent">{item.event}</span>
                  <h2 uk-slideshow-parallax="x: 200,0,0">{item.title}</h2>
                  <p uk-slideshow-parallax="x: 400,0,0;">{item.paragraph}</p>
                  <Link to={item.link} className="learnMore">
                    Learn More
                  </Link>
                </div>
              ) : (
                <div uk-slideshow-parallax="scale: 1,1,0.8">
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: {
                        sm: "1.6rem"
                      }
                    }}
                    style={{ margin: 0, fontWeight: 600 }}
                    uk-slideshow-parallax="x: 200,0,0"
                  >
                    {item.title}
                  </Typography>
                  <p uk-slideshow-parallax="x: 400,0,0;">{item.paragraph}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* uk-hidden-hover */}
      <div className="sliderControls">
        <a
          className="uk-position-center-left uk-position-small"
          uk-slideshow-item="previous"
          href="prev"
        >
          <HiOutlineArrowLeftCircle />
        </a>
        {/* Play/Pause Button */}
        <IconButton onClick={toggleAutoplay}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <a
          className="uk-position-center-right uk-position-small"
          uk-slideshow-item="next"
          href="next"
        >
          <HiOutlineArrowRightCircle />
        </a>
      </div>
      {/* <div className={`${slidePanel ? "active" : ""} slidingPanel`}>
        <div className="content">
          <img
            src={slidePanel ? slideClose : slideOpen}
            alt="slider"
            className="textClick"
            onClick={slidePanelHandler}
          />
          <h3>Event</h3>
          <h4>
            <b>Vollee</b> exhibiting at IACP Conference
          </h4>
          <p>October 19th - 22nd, 2024 Boston, MA</p>
        </div>
      </div> */}
    </div>
  );
};
export default HomeSlider;
