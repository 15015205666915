import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { MainTitle } from "./Title";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";

import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import customer from "../../assets/images/icons/customers.png";
import { GoArrowRight } from "react-icons/go";
import { BsChevronDoubleDown } from "react-icons/bs";

// import image from "../../assets/images/Speaking.jpg";

export const LocomotiveImage = (props) => {
  return (
    <Fragment>
      <Grid item xs={12} md={6} className="subTitleBlock">
        {props.content.map(
          (item, index) =>
            index % 2 === 0 && (
              <div data-scroll data-scroll-speed="0" key={index}>
                <div className="imgBlockScroll">
                  <div data-scroll data-scroll-speed="-0.2">
                    <img src={item.image} alt="..." />
                  </div>
                </div>
                <h4>{item.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
              </div>
            )
        )}
      </Grid>
      <Grid item xs={12} md={6} className="subTitleBlock">
        {props.content.map(
          (item, index) =>
            index % 2 !== 0 && (
              <div data-scroll data-scroll-speed="0" key={index}>
                <div className="imgBlockScroll">
                  <div data-scroll data-scroll-speed="-0.2">
                    <img src={item.image} alt="..." />
                  </div>
                </div>
                <h4>{item.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
              </div>
            )
        )}
      </Grid>
    </Fragment>
  );
};

// Slick Carousel Start - Eg Home Key Benefits
// Custom Previous Arrow
const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    style={{ ...style, display: "block", cursor: "pointer" }}
  >
    <svg width="14" height="24" viewBox="0 0 14 24">
      <polyline
        fill="none"
        stroke="black"
        strokeWidth="1.4"
        points="12.775,1 1.225,12 12.775,23"
      />
    </svg>
  </div>
);

// Custom Next Arrow
const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    style={{ ...style, display: "block", cursor: "pointer" }}
  >
    <svg width="14" height="24" viewBox="0 0 14 24">
      <polyline
        fill="none"
        stroke="black"
        strokeWidth="1.4"
        points="1.225,23 12.775,12 1.225,1"
      />
    </svg>
  </div>
);

export const TextCarousel = (props) => {
  const settings = {
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };
  return (
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainTitle
            subTitle={props.title.subTitle}
            title={props.title.title}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="slickKeyBenefits">
            <Slider {...settings}>
              {props.slides.map((item, index) => (
                <div key={index}>
                  <div className="title">
                    <h3>{item.title}</h3>
                    <p>{item.estimate}</p>
                  </div>
                  <p>{item.paragraph}</p>
                </div>
              ))}
            </Slider>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

// Slick Carousel End

export const ImageBlur = (props) => {
  return (
    <Container fixed>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12}>
          <MainTitle
            subTitle={props.title.subTitle}
            title={props.title.title}
            center={props.center}
          />
        </Grid>
        {props.content.map((item, index) => (
          <Grid item md={4} key={index}>
            <Box className="useCaseImage">
              <img src={item.image} alt={item.title} />
              <Box className="imageBlock">
                <h3>{item.title}</h3>
                <p>{item.paragraph}</p>
                <Link to={item.link}>
                  <Button variant="contained" className="arrow">
                    Explore
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export const InsightImage = (props) => {
  const [open, setOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({});

  var settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "60px",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: "110px"
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "70px"
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "50px"
        }
      }
    ]
  };

  const handleClickOpen = (content) => {
    setOpen(true);
    setDrawerContent(content);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainTitle title={props.title} />
        </Grid>
        <Grid item xs={12}>
          <Slider {...settings} className="slickInsights">
            {props.content.map((item, index) => (
              <Box className="insights" key={index}>
                <img src={item.image} alt={props.title} />
                <Box className="content">
                  <Box className="title">
                    <img src={item.logo} alt={props.subTitle} />
                  </Box>
                  <p>{item.subTitle}</p>
                  <Button variant="text" onClick={() => handleClickOpen(item)}>
                    Learn More
                  </Button>
                </Box>
              </Box>
            ))}
          </Slider>
        </Grid>
        {drawerContent.title && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="drawerInsight"
          >
            <DialogTitle id="alert-dialog-title">
              {drawerContent.title}
              <img src={drawerContent.logo} alt={drawerContent.logo} />
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h5>{drawerContent.subTitle}</h5>
                {drawerContent?.paragraph.map((item, index) => (
                  <Typography
                    key={index.toString()}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </Grid>
    </Container>
  );
};

export const BannerVideo = (props) => {
  return (
    <div className="innerBanner">
      <div className="overlay" />
      <ReactPlayer
        url={props.video}
        muted={true}
        playing={true}
        loop
        width="100%"
        height="100vh"
      />
      <div className="content">
        <MainTitle
          subTitle={props.title.subTitle}
          title={props.title.title}
          paragraph={props.title.paragraph}
        />
      </div>
    </div>
  );
};

export const FlipBlock = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`service-box ${
        props.customContainerClass ? props.customContainerClass : ""
      } ${props.bgImage ? "bgImage" : ""}`}
    >
      <div
        className={`${props.iconimages ? "flipoxwithimage" : ""} ${
          props.iconFront ? "iconsFront" : ""
        } service-icon`}
        style={props.image && { backgroundImage: `url('${props.image}') ` }}
      >
        {props.icon && <h6>{props.icon}</h6>}
        {props.iconimages && <img src={props.iconimages} alt={props.title} />}
        <h3>{props.title}</h3>
        {/* <TouchIcon /> */}
      </div>
      <div className="service-content">
        <h3>{props.title}</h3>
        {props.paragraph && <p>{props.paragraph}</p>}
        {props.link && (
          <Link to={props.link} target="_blank">
            Learn More
          </Link>
        )}
      </div>
    </div>
  );
};

export const VerticalTabs = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        className="tabPanels"
      >
        {value === index && (
          <Slide
            direction={"right"}
            in={value === index}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 800, exit: 500 }}
          >
            <Box>{children}</Box>
          </Slide>
        )}
      </div>
    );
  };

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainTitle
            subTitle={props.title.subTitle}
            title={props.title.title}
            paragraph={props.title.paragraph}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className="muiTabs">
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ borderRight: 1, borderColor: "divider" }}
              className="tabNav"
            >
              {props.tabs.map((item, index) => (
                <Tab
                  label={
                    <>
                      <img src={item.icon} alt={item.title} />
                      {item.title} <MdOutlineKeyboardDoubleArrowRight />
                    </>
                  }
                  key={index.toString()}
                />
              ))}
            </Tabs>

            {props.tabs.map((item, index) => (
              <TabPanel value={value} key={index.toString()} index={index}>
                <Box className="tabsContent">
                  <Typography variant="h5">{item.title}</Typography>
                  <Typography>{item.paragraph}</Typography>
                </Box>
                <img src={item.image} alt={item.title} />
              </TabPanel>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export const HoverContent = (props) => {
  return (
    <Container fixed>
      <Grid container>
        <Grid item xs={12}>
          <MainTitle
            subTitle={props.title.subTitle}
            title={props.title.title}
          />
        </Grid>
        <Grid item xs={12} className="hoverContainer">
          {props.content.map((item, index) => (
            <Box
              className="hoverContent"
              style={{ backgroundImage: `url(${item.image})` }}
              key={index.toString()}
            >
              <Box className="content">
                <span>{item.title}</span>
                <h4>{item.estimate}</h4>
                <p>{item.paragraph}</p>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export const IconHover = (props) => {
  return (
    <Container fixed>
      <Grid container>
        <Grid item xs={12}>
          <MainTitle
            subTitle={props.title.subTitle}
            title={props.title.title}
            paragraph={props.title.paragraph}
          />
        </Grid>
        {props.content.map((item, index) => (
          <Grid item xs={12} md={6} lg={3} key={index.toString()}>
            <Box className="iconHoverBox">
              <Box className="content">
                <img src={item.icon} alt={item.title} />
                <h4>{item.title}</h4>
                <h2>{item.estimate}</h2>
                <BsChevronDoubleDown />
                <p>{item.paragraph}</p>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export const SlickCustomers = (props) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div className="imageRight left"></div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainTitle title={props.title.title} center />
        </Grid>
        <Grid item xs={12}>
          <Slider
            asNavFor={nav2}
            ref={(slider) => (sliderRef1 = slider)}
            fade={true}
            className="slickCustomers"
          >
            {props.content.map((item, index) => (
              <div key={index.toString()}>
                <Box className="slickCustomer">
                  <img
                    src={customer}
                    alt="customer icons"
                    className="customer"
                  />
                  <img src={item.logo} alt={item.title} />
                  <h3>{item.title}</h3>
                  <Link to={item.link}>
                    Read More <GoArrowRight />
                  </Link>
                </Box>
              </div>
            ))}
          </Slider>
          <Slider
            asNavFor={nav1}
            ref={(slider) => (sliderRef2 = slider)}
            slidesToShow={8}
            autoplay={false}
            slidesToScroll={1}
            swipeToSlide={false}
            focusOnSelect={true}
            className="slickCustomerLogo"
          >
            {props.content.map((item, index) => (
              <div key={index.toString()}>
                <img src={item.logo} alt={item.title} />
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  );
};

export const ScenariosPages = (props) => {
  return (
    <div className="innerPage">
      <Container fixed>
        <Grid container className="bannerMain">
          <Grid item xs={12} md={6}>
            <Box className="banner">
              <Box className="title">
                <Typography variant="h5">{props.banner.subTitle}</Typography>
                <Typography variant="h4">{props.banner.title}</Typography>
              </Box>
              <Box className="content">
                <Typography variant="h3">
                  {props.banner.content.title}
                </Typography>
                <Typography>{props.banner.content.paragraph}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={props.banner.image}
              alt={props.banner.title}
              className="bannerImage"
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} className="subTitleBlock">
            {props.content.map(
              (item, index) =>
                index % 2 === 0 && (
                  <div
                    data-scroll
                    data-scroll-speed="0"
                    key={index}
                    className="imageScrollSection"
                  >
                    <div className="imgBlockScroll">
                      <div data-scroll data-scroll-speed="-0.2">
                        <img src={item.image} alt="..." />
                      </div>
                    </div>
                    <h4>{item.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                  </div>
                )
            )}
          </Grid>
          <Grid item xs={12} md={6} className="subTitleBlock">
            {props.content.map(
              (item, index) =>
                index % 2 !== 0 && (
                  <div
                    data-scroll
                    data-scroll-speed="0"
                    key={index}
                    className="imageScrollSection"
                  >
                    <div className="imgBlockScroll">
                      <div data-scroll data-scroll-speed="-0.2">
                        <img src={item.image} alt="..." />
                      </div>
                    </div>
                    <h4>{item.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                  </div>
                )
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
