import { Fragment } from "react";
import { Box, Container, Grid } from "@mui/material";
import bannerPlatform from "../assets/images/bannerPlatform.jpg";

import ai from "../assets/images/icons/ai.png";
import gear from "../assets/images/icons/gear.png";
import structure from "../assets/images/icons/structure.png";
import maze from "../assets/images/icons/maze.png";
import discovery from "../assets/images/icons/discovery.png";

import neuralAiAgent from "../assets/images/neuralAiAgent.png";
import adaptiveModeler from "../assets/images/adaptiveModeler.png";
import correlationEngine from "../assets/images/correlationEngine.png";
import cognitiveIndex from "../assets/images/cognitiveIndex.png";
import discoveryFabric from "../assets/images/discoveryFabric.png";

const bannerContent = {
  image: bannerPlatform,
  subTitle: "Platform Capabilities",
  title: "Deep insight with agility & resilience",
  paragraph: [
    "The Vollee Platform closely matches each necessary function of generating intelligent insights with the AI service best suited for the job.",
    "Starting with secure, resilient, and compliant data storage, Vollee establishes a unified data estate that supports comprehensive search & discoverability. Vollee then associates and correlates matching data points with contextual information, aggregating variables such as location, date, and time into one blended, meaningful view. From there, Vollee inferences the data against custom, pre-built ML models to deliver intelligent recommendations through immersive service dashboards. Finally, Vollee interacts with you via an advanced, Conversational AI-driven virtual agent, providing a natural, organic feel to the entire process."
  ]
};

const productivities = [
  {
    icon: ai,
    image: neuralAiAgent,
    title: "Dynamic Agent",
    paragraph:
      "By using advanced AI services such as NLP, Conversational AI, and Bot Services, Vollee can interact with you in a fluid, natural manner that keeps your focus on the topic at hand as opposed to your communication methodology."
  },
  {
    icon: gear,
    image: adaptiveModeler,
    title: "Adaptive Modeler",
    paragraph:
      "Vollee leans on fine-tuned, custom ML models that are purpose built to solve core challenges with exceptionally high levels of accuracy. Each model is modularized to adapt and primed for continuous learning to deliver contextual outcomes aligned with your goals."
  },
  {
    icon: structure,
    image: correlationEngine,
    title: "Correlation Graph",
    paragraph:
      "Vollee makes intuitive associations to best correlate and present insights through ‘always on’ dashboards that stitch together subject matter areas of relevance, thereby increasing understanding of the data and spurring innovative thinking."
  },
  {
    icon: maze,
    image: cognitiveIndex,
    title: "Cognitive Index",
    paragraph:
      "Vollee ingests your multimedia content using Neural AI services to create a cognitive index that automatically translates languages, identifies speakers, creates time stamps, and turns your unstructured media into a searchable, inter-connected storyline."
  },
  {
    icon: discovery,
    image: discoveryFabric,
    title: "Discovery Fabric",
    paragraph:
      "Vollee establishes discoverability across your data estate by unifying data shares, access locations, and different file types into a broad informational fabric that instantly connects you to each and every single piece of relevant content."
  }
];
const Productivity = () => {
  return (
    <Fragment>
      <div>
        <section className="innerBannerContainer">
          <div>
            <Box
              className="imageBanner"
              sx={{ backgroundImage: `url(${bannerContent.image})` }}
            >
              <Container fixed>
                <Grid container>
                  <Grid item xs={12}>
                    <h5>{bannerContent.subTitle}</h5>
                    <h1>{bannerContent.title}</h1>
                    {bannerContent.paragraph.map((item, index) => (
                      <p key={index.toString()}>{item}</p>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </div>
        </section>
        <div className="productivityBody">
          <Container fixed>
            <Grid container>
              <Grid item xs={12}>
                <div className="borderedLeft" />
                {productivities.map((item, index) => (
                  <Box className="content" key={index.toString()}>
                    <Box className="left">
                      <span>
                        <img src={item.icon} alt={item.title} /> {item.title}
                      </span>
                      {/* <h3>{item.title}</h3> */}
                      <p>{item.paragraph}</p>
                    </Box>
                    <Box className="right">
                      <img src={item.image} alt={item.title} />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Productivity;
