import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Fragment, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import global from "../assets/images/icons/global.png";
import Facebook from "../assets/images/social/Facebook.png";
import Twitter from "../assets/images/social/X.png";
import Linkedin from "../assets/images/social/Linkedin.png";
import Instagram from "../assets/images/social/Instagram.png";
import DrawerContact from "./UI/DrawerContact";

const social = [
  {
    image: Linkedin,
    link: "https://www.linkedin.com/company/VolleeAI/"
  },
  {
    image: Facebook,
    link: "https://www.facebook.com/VolleeAI"
  },
  {
    image: Twitter,
    link: "https://x.com/VolleeAI"
  },
  {
    image: Instagram,
    link: "https://www.instagram.com/VolleeAI/"
  }
];

const company = [
  {
    title: "Home",
    link: "/"
  },
  {
    title: "Platform",
    link: "/platform"
  },
  {
    title: "Privacy Policy",
    link: "/privacy_policy"
  },
  {
    title: "Terms of Use",
    link: "/terms_of_use"
  }
];
const topics = [
  {
    title: "Education",
    link: "/education"
  },
  {
    title: "Public Safety",
    link: "/public-safety"
  },
  {
    title: "Productivity",
    link: "/productivity"
  },
  {
    title: "Customers",
    link: "/customers/british-council"
  }
];

const Footer = () => {
  const location = useLocation();
  // Create a reference to the child component
  const contactDrawer = useRef();
  // Function to trigger the child's function from the parent
  const triggerContactForm = () => {
    contactDrawer.current.openContactDrawer(); // Access the child's function
  };

  const scrollPage = (link) => {
    if (location.pathname === link) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  return (
    <Fragment>
      <section className="bgBlue">
        <Container fixed>
          <Grid container>
            <Grid item xs={12}>
              <Box className="getInTouch">
                <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12}>
                    <div className="titleContact">
                      <Typography className="coloredText">
                        Contact Us
                      </Typography>
                      <Typography variant="h5">
                        Ready to Transform with AI-Driven Solutions?
                      </Typography>
                      <Typography>
                        Whether you’re from education, public safety, or
                        government, <b>Vollee</b> is here to empower your
                        organization with AI-powered insights and advanced
                        tools. Got questions, need a demo, or want to discuss
                        how we can work together? <br />
                        <b>We’d love to hear from you.</b>
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={triggerContactForm}
                        className="arrow"
                      >
                        Get in Touch
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <DrawerContact ref={contactDrawer} />
      <footer>
        <Container fixed>
          <Grid container>
            <Grid item xs={12} md={3}>
              <h4>COMPANY</h4>
              <ul className="footerLinks">
                {company.map((item, index) => (
                  <li key={index.toString()}>
                    <Link
                      to={item.link}
                      onClick={() => {
                        scrollPage(item.link);
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} md={3}>
              <h4>TOPICS</h4>
              <ul className="footerLinks">
                {topics.map((item, index) => (
                  <li key={index.toString()}>
                    <Link
                      to={item.link}
                      onClick={() => {
                        scrollPage(item.link);
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={12} md={3}>
              <h4>GLOBAL SUPPORT</h4>
              <img
                src={global}
                alt="Global Support"
                className="globalSupport"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <h4>SOCIAL MEDIA</h4>
              <Box className="footerSocial">
                {social.map((item, index) => (
                  <Link to={item.link} target="_blank" key={index.toString()}>
                    <img src={item.image} alt="social media" />
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Typography variant="body2" className="copyright">
            © {new Date().getFullYear()} Vollee. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
