import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
      <ErrorBoundary>
          <App />
      </ErrorBoundary>
  </BrowserRouter>
);
reportWebVitals();
