import { ScenariosPages } from "./UI/ContentBlocks";
import { WebsiteContentObject } from "./WebsiteContentObject";
import LocomotiveScroll from "locomotive-scroll";

const root = WebsiteContentObject.Productivity;
const Platform = () => {
  if (window.screen.width >= 767) {
    (function () {
      new LocomotiveScroll();
    })();
  }
  return <ScenariosPages banner={root.banner} content={root.content} />;
};

export default Platform;
