import { CheckCircle, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

const DrawerContact = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    commonMessage: "",
    fullName: false,
    email: false,
    subject: false,
    query: false
  });

  const inputFullName = useRef("");
  const inputEmail = useRef("");
  const inputSubject = useRef("");
  const inputMessage = useRef("");

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    if (!newOpen) resetForm();
  };
  const resetForm = () => {
    setSuccess(false);
    setLoading(false);
    setError({
      commonMessage: "",
      fullName: false,
      email: false,
      subject: false,
      query: false
    });
  };

  const validateForm = () => {
    let isValid = true;
    const emailValidate = /\S+@\S+\.\S+/;

    // Check Full Name
    if (!inputFullName.current.value) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        fullName: true,
        commonMessage: "Please fill out all required fields."
      }));
    } else {
      setError((prev) => ({ ...prev, fullName: false }));
    }

    // Check Email
    if (!inputEmail.current.value) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        email: true,
        commonMessage: "Please fill out all required fields."
      }));
    } else if (!emailValidate.test(inputEmail.current.value)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        email: true,
        commonMessage: "Please enter a valid email address."
      }));
    } else {
      setError((prev) => ({ ...prev, email: false }));
    }

    // Check Subject
    if (!inputSubject.current.value) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        subject: true,
        commonMessage: "Please fill out all required fields."
      }));
    } else {
      setError((prev) => ({ ...prev, subject: false }));
    }

    // Check Message
    if (!inputMessage.current.value) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        query: true,
        commonMessage: "Please fill out all required fields."
      }));
    } else {
      setError((prev) => ({ ...prev, query: false }));
    }

    return isValid;
  };

  const submitFormHandler = async () => {
    if (!validateForm()) return;

    setLoading(true);
    const payload = {
      full_name: inputFullName.current.value,
      email: inputEmail.current.value,
      subject: inputSubject.current.value,
      query: inputMessage.current.value
    };

    try {
      const response = await axios.post(
        "https://vollee-lite-middleware.azurewebsites.us/v1/users/request/sales",
        payload
      );

      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          // Close the drawer after 4 seconds
          setOpen(false);
          setSuccess(false);
        }, 4000);
      }
    } catch (error) {
      setError((prev) => ({
        ...prev,
        commonMessage: "Failed to submit the form. Please try again."
      }));
    } finally {
      setLoading(false);
    }
  };

  const openContactDrawer = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    openContactDrawer
  }));
  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false)}
      anchor="bottom"
      className="formDrawer"
    >
      <Button
        variant="text"
        onClick={toggleDrawer(false)}
        className="drawerClose"
      >
        <Close />
      </Button>
      <Container fixed>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} md={7}>
            {success ? (
              <Box textAlign="center">
                <CheckCircle color="success" fontSize="large" />
                <Typography variant="h6" color="success.main">
                  Thank you! Your request has been submitted.
                </Typography>
              </Box>
            ) : (
              <form>
                <TextField
                  error={error.fullName}
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  autoComplete={"off"}
                  inputRef={inputFullName}
                />
                <TextField
                  error={error.email}
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  autoCapitalize={"off"}
                  autoComplete={"off"}
                  inputRef={inputEmail}
                />
                <TextField
                  error={error.subject}
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  autoComplete={"off"}
                  inputRef={inputSubject}
                />
                <TextField
                  error={error.query}
                  label="Your Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  autoComplete={"off"}
                  inputRef={inputMessage}
                />
                {error.commonMessage && (
                  <Typography color="error">{error.commonMessage}</Typography>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  onClick={submitFormHandler}
                  className="btnSubmit"
                >
                  {loading ? <CircularProgress size={24} /> : "Send Mail"}
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
});
export default DrawerContact;
