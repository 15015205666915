import { Fragment } from "react";
import { Container, Grid } from "@mui/material";

const TermsOfUse = () => {
  return (
    <Fragment>
      <section className="policyTerm">
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={9}>
              <h1>Terms of Use</h1>
              <p>
                Vollee is a proprietary software product owned by Tabtu Corp.
                All rights and interests in and to the Vollee software and any
                associated intellectual property are the exclusive property of
                Tabtu Corp.
              </p>
              <p>
                Tabtu Corp (“*,” “we,” “us”, “our”) provides public access to
                this website as a convenience, but subject to the following
                terms of use (“Terms of Use”). By accessing, viewing, using or
                downloading materials from this website, you agree to be bound
                by these terms and conditions, and if you do not agree with
                these Terms of Use do not use this website.
              </p>
              <h3>Privacy And Protection Of Personal Information.</h3>
              <p>
                See the Privacy Statement disclosures relating to the collection
                and use of your information.
              </p>
              <h3>Use of Materials on our Website</h3>
              <p>
                Unless otherwise specified, you may view and download the
                materials or software on this website only for your personal,
                informational, and noncommercial purposes, provided that you
                retain all copyright and other proprietary notices contained in
                the original materials on any copies of the materials. You may
                not modify the materials or software on this website in any way
                or reproduce or publicly display, create derivative works from,
                perform, post, transmit, distribute or otherwise use them for
                any public or commercial purpose without written permission by
                *.
              </p>
              <h3>Use of Downloadable Software</h3>
              <p>
                Any software (“Software”) that may be downloaded from this
                website is licensed subject to the terms of the applicable
                license agreement (“License Agreement”). Except for rights
                granted pursuant to such license agreement, * reserves all
                intellectual property rights, title and interest in such
                Software. You will be unable to install any Software that is
                accompanied by or includes a License Agreement, unless you first
                agree to the License Agreement terms. The Software is made
                available for download solely for use by end users according to
                the License Agreement. Any reproduction or redistribution of the
                Software not in accordance with the License Agreement is
                expressly prohibited by law.
              </p>
              <p>
                WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE
                SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER
                REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED, UNLESS
                SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PERMITTED BY
                THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. THE SOFTWARE
                IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE
                LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT,
                * HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO
                THE SOFTWARE, INCLUDING ALL WARRANTIES AND CONDITIONS OF
                MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS
                FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
              </p>
              <h3>Third Party Descriptions</h3>
              <p>
                This website may contains information concerning various third
                parties (often referred to as “partners”) who offer products or
                services compatible with our products and solutions. This
                information is provided for your convenience only. We are not
                responsible for and does not warrant any third party product or
                service or the accuracy of any description.
              </p>
              <h3>Trademark Information</h3>
              <p>
                All trademarks, logos, and service marks (“Marks”), if any,
                displayed on this Website are the property of *, or other third
                parties. You are not permitted to use the Marks without the
                prior written consent of * or such third party that may own the
                Marks.
              </p>
              <h3>Links to Other Websites</h3>
              <p>
                We may provide links to other websites as a convenience to you;
                the inclusion of any link does not imply endorsement by * of the
                website. * does not monitor nor control the content of any other
                website and assumes no responsibility or liability of any kind
                for any material or communications available at such websites. *
                is not responsible for the content of any website that may be
                linked to this website.
              </p>
              <h3>Changes to these Terms of Use</h3>
              <p>
                * may at any time revise these Terms of Use by updating this
                posting. You are bound by any such revisions.
              </p>
              <h3>Disclaimer of Warranty</h3>
              <p>
                Information on this website may be changed or updated without
                notice, may contain factual inaccuracies or typographical
                errors, or be incomplete or out of date. * may make changes
                and/or improvements in the products described in this website at
                any time without notice.
              </p>
              <p>
                THIS WEBSITE AND THE MATERIALS AND INFORMATION CONTAINED HEREIN
                OR IN ANY THIRD- PARTY WEBSITE LINKED HERETO ARE PROVIDED “AS
                IS” AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, AND * DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. * DOES
                NOT REPRESENT OR WARRANT THAT THE WEBSITE WILL OPERATE WITHOUT
                INTERRUPTION OR BE ERROR-FREE, THAT DEFECTS WILL BE CORRECTED,
                OR THAT THIS WEBSITE, ANY WEBSITE LINKED HERETO, OR THE
                UNDERLYING SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS. * DOES NOT MAKE ANY WARRANTIES OR REPRESENTATIONS
                REGARDING THE CORRECTNESS, ACCURACY, ADEQUACY, USEFULNESS,
                TIMELINESS OR RELIABILITY OF THE MATERIALS IN THIS WEBSITE OR
                ANY LINKED WEBSITES.
              </p>
              <h3>Limitation Of Liability</h3>
              <p>
                IN NO EVENT SHALL * OR ITS SUPPLIERS BE LIABLE TO YOU OR ANY
                THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER
                RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN
                ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING
                OUT OF OR IN CONNECTION WITH THE USE OF THIS WEBSITE OR OF ANY
                LINKED WEBSITES. SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR
                LIMITATION OF CERTAIN TYPES OF DAMAGES, SO THESE LIMITATIONS MAY
                NOT APPLY TO YOU.
              </p>
              <h3>Indemnification</h3>
              <p>
                aaaYou agree to indemnify, defend, and hold harmless *, its
                officers, directors, employees and agents, from and against all
                claims, demands, losses, expenses, damages and costs, including
                reasonable attorneys’ fees, arising from any violation of these
                terms or your use or connection to the website.
              </p>
              <h3>Applicable Laws</h3>
              <p>
                This website is controlled by * from its offices in Bellevue,
                Washington, USA. * makes no representation that materials at
                this website are appropriate or available for use outside of the
                United States and access to them from territories where their
                contents are illegal is prohibited. Those who access this
                website from other jurisdictions do so on their own initiative
                and are responsible for compliance with applicable local laws.
                Any dispute arising out of or in connection with the use or
                access or otherwise related to this website shall be finally
                settled by the Superior Court of King County, Washington.
              </p>
              <h3>Member Account, Password, And Security.</h3>
              <p>
                If any of the Software or other materials on this website
                requires you to open an account, you must complete the
                registration process by providing us with current, complete and
                accurate information as prompted by the applicable registration
                form. You also will choose a password and a user name. You are
                entirely responsible for maintaining the confidentiality of your
                password and account. Furthermore, you are entirely responsible
                for any and all activities that occur under your account. You
                agree to notify * immediately of any unauthorized use of your
                account or any other breach of security. * will not be liable
                for any loss that you may incur as a result of someone else
                using your password or account, either with or without your
                knowledge. However, you could be held liable for losses incurred
                by * or another party due to someone else using your account or
                password. You may not use anyone else’s account at any time,
                without the permission of the account holder.
              </p>
              <h3>No Unlawful Or Prohibited Use.</h3>
              <p>
                As a condition of your use of the website, you will not use the
                website for any purpose that is unlawful or prohibited by these
                Terms of Use.
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fragment>
  );
};

export default TermsOfUse;
