import React, {Component, Fragment} from 'react';
import { Button, Box, Typography } from '@mui/material';
import Header from "../Header";
import Footer from "../Footer";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    handleReload = () => {
        this.setState({ hasError: false });
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            // Custom fallback UI
            return (
                <Fragment>
                    <Header />
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="90vh"
                        textAlign="center"
                        p={2}
                        bgcolor="#f5f5f5"
                    >
                        <Typography variant="h4" gutterBottom>
                            Something went wrong.
                        </Typography>
                        <Typography variant="body1" gutterBottom color={"black"}>
                            An unexpected error has occurred. Please try again later.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleReload}
                            sx={{
                                mt: 2,
                                backgroundColor: '#9cdbfc',
                                color: 'black',
                                '&:hover': {
                                    backgroundColor: '#0c2331',
                                    color: 'white',
                                }
                            }}
                        >
                            Reload Page
                        </Button>
                    </Box>
                    <Footer />
                </Fragment>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
