import "uikit/dist/css/uikit.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import Education from "./components/Education";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Index from "./components/Index";
import Layout from "./components/Layout";
import Safety from "./components/Safety";
import Productivity from "./components/Productivity";
import ScrollToTop from "./components/ScrollToTop";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material";
import Platform from "./components/Platform";
import Customers from "./components/Customers";
import NotFound from "./components/NotFound/NotFound";

const theme = createTheme({
  typography: {
    // Set a custom font family for MUI
    fontFamily: '"Montserrat", sans-serif'
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/customers/:userId" element={<Customers />} />
          <Route
            path="/customers"
            element={<Navigate to={"/customers/british-council"} />}
          />
          <Route path="/education" element={<Education />} />
          <Route path="/public-safety" element={<Safety />} />
          <Route path="/productivity" element={<Productivity />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
