import { Fragment, useEffect, useRef, useState } from "react";
import logo from "../assets/images/vollee_logo.svg";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Box,
  Container,
  Grid,
  useMediaQuery,
  useTheme
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DrawerContact from "./UI/DrawerContact";

const menuItems = [
  {
    text: "Platform",
    link: "/platform",
    parentPath: "platform"
  },
  {
    text: "Education",
    link: "/education",
    parentPath: "education"
  },
  {
    text: "Public Safety",
    link: "/public-safety",
    parentPath: "public-safety"
  },
  {
    text: "Productivity",
    link: "/productivity",
    parentPath: "productivity"
  },
  {
    text: "Customers",
    link: "/customers/british-council",
    parentPath: "customers"
  }
];

const MainMenu = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isActive = (path) => {
    let currentParentPath = location.pathname
      .split("/")
      .filter((segment) => segment);
    return currentParentPath[0] === path ? "active" : "";
  };

  // Create a reference to the child component
  const contactDrawer = useRef();

  // Function to trigger the child's function from the parent
  const triggerChildFunction = () => {
    contactDrawer.current.openContactDrawer(); // Access the child's function
  };

  return (
    <>
      <AppBar position="static" className="headerInner">
        <Container fixed>
          <Grid container>
            <Toolbar>
              {/* Left Logo */}
              <Link to={"/"}>
                <img src={logo} alt="Vollee Logo" className="logo" />
              </Link>

              {/* Right Menu Items (Hidden on mobile) */}
              <Box className="menuItems">
                {menuItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    className={isActive(item.parentPath)}
                  >
                    {item.text}
                  </Link>
                ))}
                <Link onClick={triggerChildFunction}>Contact Us</Link>
              </Box>
              <DrawerContact ref={contactDrawer} />

              {/* Hamburger Menu Icon (Shown on mobile) */}
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{ display: { xs: "block", md: "none" } }} // Shown on mobile, hidden on desktop
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Grid>
        </Container>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            transition: "all 0.3s ease-in-out"
          }
        }}
        className="menuDrawer"
      >
        <IconButton onClick={toggleDrawer}>
          <CloseIcon />
        </IconButton>
        <List className="itemList">
          <ListItem button>
            <Link to={"/"} onClick={toggleDrawer}>
              Home
            </Link>
          </ListItem>
          {menuItems.map((item, index) => (
            <ListItem button key={index}>
              <Link
                to={item.link}
                className={isActive(item.parentPath)}
                onClick={toggleDrawer}
              >
                {item.text}
              </Link>
            </ListItem>
          ))}
          <ListItem button>
            <Link onClick={triggerChildFunction}>Contact Us</Link>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

const HeaderHome = () => {
  const [logoVisible, setLogoVisible] = useState(true);
  const [isActive, setIsActive] = useState(false);
  // Create a reference to the child component
  const contactDrawer = useRef();

  // Function to trigger the child's function from the parent
  const triggerChildFunction = () => {
    contactDrawer.current.openContactDrawer(); // Access the child's function
  };

  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const listenToScroll = () => {
    let heightToHideFrom = lessThanSmall ? 0 : 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      logoVisible && setLogoVisible(false);
    } else {
      setLogoVisible(true);
    }
  };
  const clickedMenu = () => {
    setIsActive((current) => !current);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });
  return (
    <Fragment>
      <DrawerContact ref={contactDrawer} />
      <header>
        <Link to="/" className={`logo ${!logoVisible ? "hide" : ""}`}>
          <img src={logo} alt="vollee logo" />
        </Link>
        <div
          className={isActive ? "open" : ""}
          id="navIcon"
          onClick={clickedMenu}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`menu_overlay ${isActive ? "open" : ""}`}>
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link key={index} to={item.link} onClick={clickedMenu}>
                  {item.text}
                </Link>
              </li>
            ))}
            <li>
              <Link onClick={triggerChildFunction}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </header>
    </Fragment>
  );
};

const Header = () => {
  const [headerChange, setHeaderChange] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setHeaderChange(true);
    } else {
      setHeaderChange(false);
    }
  }, [location]);
  return <Fragment>{headerChange ? <HeaderHome /> : <MainMenu />}</Fragment>;
};

export default Header;
