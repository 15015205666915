import { WebsiteContentObject } from "./WebsiteContentObject";
import LocomotiveScroll from "locomotive-scroll";
import { ScenariosPages } from "./UI/ContentBlocks";

const Education = () => {
  if (window.screen.width >= 767) {
    (function () {
      new LocomotiveScroll();
    })();
  }
  const root = WebsiteContentObject.Education;
  return <ScenariosPages banner={root.banner} content={root.content} />;
};

export default Education;
