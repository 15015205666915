import { Fragment } from "react";
import LocomotiveScroll from "locomotive-scroll";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import HomeSlider from "./slider/HomeSlider";
import { WebsiteContentObject } from "./WebsiteContentObject";
import { IconHover, ImageBlur, SlickCustomers } from "./UI/ContentBlocks";
import { Box, Typography } from "@mui/material";

import cloud from "../assets/images/bgCloud.png";
import platformCapabilities from "../assets/images/platformCapabilities.png";
import arrow from "../assets/images/icons/arrowBlue.png";
import graphic from "../assets/images/cloudVault.png";
import { MainTitle } from "./UI/Title";
import { Link } from "react-router-dom";
import Testimonials from "./Testimonials";

const Index = () => {
  if (window.screen.width >= 767) {
    (function () {
      new LocomotiveScroll();
    })();
  }

  return (
    <Fragment>
      <div data-scroll-container>
        <section data-scroll-section id="Home">
          <div data-scroll data-scroll-speed="-1">
            <HomeSlider sliderContent={WebsiteContentObject.Slides} />
          </div>
        </section>

        <section data-scroll-section className="bgBlack" id="Capabilities">
          <div data-scroll data-scroll-speed="0" className="cloudCapabilities">
            <Container fixed>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <div className="imageRight"></div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MainTitle
                    subTitle={WebsiteContentObject.Capabilities.title.subTitle}
                    title={WebsiteContentObject.Capabilities.title.title}
                    paragraph={
                      WebsiteContentObject.Capabilities.title.paragraph
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    className="platformCloud"
                    sx={{ backgroundImage: `url(${cloud})` }}
                  >
                    <img
                      src={platformCapabilities}
                      alt="platform"
                      className="platform"
                    />
                    <Box
                      className="content"
                      sx={{ backgroundImage: `url(${cloud})` }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ backgroundImage: `url(${cloud})` }}
                      >
                        <img src={cloud} alt="arrow" /> Neural AI Engine
                      </Typography>
                      <Typography sx={{}}>
                        The Neural AI Engine powers Vollee's core capabilities,
                        delivering real-time insights, adaptive learning, and
                        intelligent data processing. Built on a secure and
                        resilient infrastructure, it safeguards your data
                        against threats while ensuring uninterrupted global
                        performance. With compliance across industry standards,
                        the Neural AI Engine keeps your operations seamless and
                        future-ready.
                      </Typography>
                      <Link to={"/platform"}>
                        Learn more about the Vollee Platform{" "}
                        <img src={arrow} alt="arrow" />
                      </Link>
                    </Box>
                    <img src={graphic} alt="graphic" className="graphic" />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </section>

        <section
          data-scroll-section
          className="bgGray"
          style={{ position: "relative" }}
        >
          <div data-scroll data-scroll-speed="0">
            <ImageBlur
              title={WebsiteContentObject.UseCase.title}
              content={WebsiteContentObject.UseCase.content}
              center
            />
          </div>
        </section>

        <section
          data-scroll-section
          className="bgWhite"
          id="KeyBenefits"
          style={{ position: "relative" }}
        >
          <div data-scroll data-scroll-speed="0">
            <IconHover
              title={WebsiteContentObject.KeyBenefits.title}
              content={WebsiteContentObject.KeyBenefits.Benefits}
            />
          </div>
        </section>

        <section
          data-scroll-section
          className="bgBlue"
          id="Customers"
          style={{ position: "relative" }}
        >
          <div data-scroll data-scroll-speed="0">
            <SlickCustomers
              title={WebsiteContentObject.customerSlide.title}
              content={WebsiteContentObject.customerSlide.content}
            />
          </div>
        </section>
        <section
          data-scroll-section
          className="bgBlack"
          id="Capabilities"
          style={{ position: "relative" }}
        >
          <div data-scroll data-scroll-speed="0">
            <Testimonials
              title={WebsiteContentObject.testimonials.title.title}
              subTitle={WebsiteContentObject.testimonials.title.subTitle}
              cardsData={WebsiteContentObject.testimonials.testimonialsdetails}
            />
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Index;
