import {Typography} from "@mui/material";

export const MainTitle = (props) => {
  return (
    <div className={`mainTitle ${props.center ? "titleCenter" : ""}`}>
      {props.subTitle && <Typography  variant="h5">{props.subTitle}</Typography>}
      <h2 dangerouslySetInnerHTML={{ __html: props.title }} />
      {props.paragraph && <p>{props.paragraph}</p>}
    </div>
  );
};
