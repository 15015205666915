import assess from "../assets/videos/assess.mp4";
import improve from "../assets/videos/improve.mov";
import measure from "../assets/videos/measure.mp4";
import homeBanner1 from "../assets/images/homeBanner1.png";
// accordion

import bodyCam from "../assets/images/bodyCam.jpg";
import education from "../assets/images/education.jpg";
import skilling from "../assets/images/skilling.jpg";
import imgPublic from "../assets/images/public.jpg";
import universal from "../assets/images/universal.jpg";
import Scientist from "../assets/images/Scientist.jpg";
import Safety from "../assets/images/Safety.jpg";
import britishCouncil from "../assets/images/britishCouncil.png";
import UAE from "../assets/images/UAE.png";
// Capabilities
import neuralAiAgent from "../assets/images/neuralAiAgent.png";
import cloudVault from "../assets/images/cloudVault.png";
import adaptiveModeler from "../assets/images/adaptiveModeler.png";
import correlationEngine from "../assets/images/correlationEngine.png";
import cognitiveIndex from "../assets/images/cognitiveIndex.png";
import discoveryFabric from "../assets/images/discoveryFabric.png";
import ai from "../assets/images/icons/ai.png";
import gear from "../assets/images/icons/gear.png";
import structure from "../assets/images/icons/structure.png";
import maze from "../assets/images/icons/maze.png";
import discovery from "../assets/images/icons/discovery.png";
import caseEducating from "../assets/images/caseEducating.png";
import caseSafety from "../assets/images/caseSafety.png";
import caseProductivity from "../assets/images/caseProductivity.png";
// KeyBenefits
import graph from "../assets/images/icons/graph.png";
import rocket from "../assets/images/icons/rocket.png";
import target from "../assets/images/icons/target.png";
import timer from "../assets/images/icons/timer.png";
// customers
import img1 from "../assets/images/logos/1.png";
import img2 from "../assets/images/logos/2.png";
import img3 from "../assets/images/logos/3.png";
import img4 from "../assets/images/logos/4.png";
import img5 from "../assets/images/logos/5.png";
import img6 from "../assets/images/logos/6.png";
import img7 from "../assets/images/logos/7.png";
import img8 from "../assets/images/logos/8.png";
// Education
import educationMain from "../assets/images/educationMain.png";
import edu1 from "../assets/images/edu1.png";
import edu2 from "../assets/images/edu2.png";
import edu3 from "../assets/images/edu3.png";
import edu4 from "../assets/images/edu4.png";
import edu5 from "../assets/images/edu5.png";
// Public Safety
import safetyMain from "../assets/images/safetyMain.png";
import safety1 from "../assets/images/safety1.png";
import safety2 from "../assets/images/safety2.png";
import safety3 from "../assets/images/safety3.png";
import safety4 from "../assets/images/safety4.png";
import safety5 from "../assets/images/safety5.png";
import safety6 from "../assets/images/safety6.png";
import safety7 from "../assets/images/safety7.png";
import safety8 from "../assets/images/safety8.png";
// Productivity
import productivityMain from "../assets/images/productivityMain.png";
import productivity1 from "../assets/images/productivity1.png";
import productivity2 from "../assets/images/productivity2.png";
import productivity3 from "../assets/images/productivity3.png";
import productivity4 from "../assets/images/productivity4.png";
import productivity5 from "../assets/images/productivity5.png";
import productivity6 from "../assets/images/productivity6.png";
// testimonials
import mslogo from "../assets/images/logos/ms-Logo.png";
import alliance from "../assets/images/logos/alliance.png";
import arlington from "../assets/images/logos/arlington.png";
import middletownpolice from "../assets/images/logos/middletownpolice.png";
import universityofflorida from "../assets/images/logos/universityofflorida.png";
import uae from "../assets/images/logos/uae.png";
// customers
import britishCouncilL from "../assets/images/customers/britishCouncil.png";
import arlingtonL from "../assets/images/customers/arlington.png";
import babson from "../assets/images/customers/babson.png";
import allianceL from "../assets/images/customers/alliance.png";
import rsci from "../assets/images/customers/rsci.png";
import UFL from "../assets/images/customers/UFL.png";
import mtpd from "../assets/images/customers/mtpd.png";
import UAEL from "../assets/images/customers/UAE.png";

export const WebsiteContentObject = {
  Slides: [
    {
      image: homeBanner1,
      title:
        "Video, audio, image and document analysis platform with Neural AI - providing contextual insights to organizations."
    },
    {
      video: assess,
      title: "Assess",
      paragraph: "Intelligent evaluation of people, processes and outcomes."
    },
    {
      video: measure,
      title: "Analyze",
      paragraph: "Integrated insights for data-driven decision-making."
    },
    {
      video: improve,
      title: "Discovery",
      paragraph: "Identify hidden patterns, trends and correlations."
    }
    // {
    //   video: assess,
    //   title: "Vollee exhibiting at IACP Conference",
    //   paragraph: "October 6th - 9th, 2024 San Diego, CA",
    //   event: "Event",
    //   link: "#"
    // }
  ],
  Capabilities: {
    title: {
      subTitle: "Platform Capabilities",
      title: "Deep insight with agility & resilience",
      paragraph:
        "The Vollee Platform closely matches each necessary function of generating intelligent insights with the AI service best suited for the job."
    },
    Accordion: [
      {
        icon: ai,
        title: "Cloud Vault",
        paragraph:
          "Vollee operates within a secure, resilient computing environment that protects your applications and data against threats, maintains service continuity throughout the world, and is operationally compliant with multiple industry regulatory bodies.",
        image: cloudVault
      },
      {
        icon: ai,
        title: "Dynamic Agent",
        paragraph:
          "By using advanced AI services such as NLP, Conversational AI, and Bot Services, Vollee can interact with you in a fluid, natural manner that keeps your focus on the topic at hand as opposed to your communication methodology.",
        image: neuralAiAgent
      },
      {
        icon: gear,
        title: "Adaptive Modeler",
        paragraph:
          "Vollee leans on fine-tuned, custom ML models that are purpose built to solve core challenges with exceptionally high levels of accuracy. Each model is modularized to adapt and primed for continuous learning to deliver contextual outcomes aligned with your goals.",
        image: adaptiveModeler
      },
      {
        icon: structure,
        title: "Correlation Graph",
        paragraph:
          "Vollee makes intuitive associations to best correlate and present insights through ‘always on’ dashboards that stitch together subject matter areas of relevance, thereby increasing understanding of the data and spurring innovative thinking.",
        image: correlationEngine
      },
      {
        icon: maze,
        title: "Cognitive Index",
        paragraph:
          "Vollee ingests your multimedia content using Neural AI services to create a cognitive index that automatically translates languages, identifies speakers, creates time stamps, and turns your unstructured media into a searchable, inter-connected storyline.",
        image: cognitiveIndex
      },
      {
        icon: discovery,
        title: "Discovery Fabric",
        paragraph:
          "Vollee establishes discoverability across your data estate by unifying data shares, access locations, and different file types into a broad informational fabric that instantly connects you to each and every single piece of relevant content.",
        image: discoveryFabric
      }
    ]
  },
  CorePrinciples: {
    title: "Core Principles",
    Principles: [
      {
        title: "AI for Good",
        image: bodyCam,
        paragraph:
          "At the heart of Vollee’s mission is the belief that AI should be a force for positive change. Our AI solutions are designed to enhance societal well-being by empowering industries like public safety, justice, and education with tools that improve outcomes, efficiency, and fairness. We are committed to using AI ethically to create meaningful impact, ensuring that technology serves humanity for the better."
      },
      {
        title: "Security",
        image: skilling,
        paragraph:
          "Vollee prioritizes the highest levels of data security to protect sensitive information. Our platform is built with robust encryption, strict access controls, and advanced threat detection, ensuring that your data is always safe. From law enforcement evidence to academic assessments, Vollee ensures that all data interactions remain secure and confidential, adhering to industry-leading security standards."
      },
      {
        title: "Transparency",
        image: universal,
        paragraph:
          "Transparency is a cornerstone of Vollee’s AI operations. We provide clear, explainable results, so users understand how conclusions are reached, ensuring trust in AI-driven decisions. Whether delivering citations for evidence analysis or showing the logic behind a recommendation, Vollee offers full visibility into the data and processes behind its intelligent recommendations."
      },
      {
        title: "Governance",
        image: education,
        paragraph:
          "Vollee adheres to stringent governance protocols, ensuring compliance with industry regulations and legal standards, using secure government cloud environments and adhering to <a href='https://www.cjis.gov/' target='_blank'>CJIS security policies</a>.  With integrated governance mechanisms, Vollee provides full accountability, compliance, and transparency, empowering organizations to trust the AI while meeting regulatory and legal requirements."
      },
      {
        title: "Scalability",
        image: imgPublic,
        paragraph:
          "Vollee is built to grow with your needs, offering flexible AI solutions that can scale effortlessly across various departments, organizations, or industries. Whether handling a small-scale operation or a nationwide implementation, our platform adapts to increasing data volumes, user bases, and complexity without compromising performance. Vollee ensures your organization can continue to leverage AI effectively, no matter the size of the challenge."
      }
    ]
  },
  KeyBenefits: {
    title: {
      subTitle: "Key Benefits",
      title: "Seamless Integration Across Industries",
      paragraph:
        "Vollee’s adaptable AI platform offers tailored solutions for multiple sectors, enhancing operations and decision-making efficiency by up to 70% in industries like public safety, justice, and education."
    },
    Benefits: [
      {
        icon: graph,
        title: "Boost organizational productivities by",
        estimate: "1000x",
        paragraph:
          "Vollee automates transcription, evidence analysis, and document review, allowing teams to process data faster and reduce manual workloads by up to 90%, freeing up valuable time for strategic decision-making.",
        image: bodyCam
      },
      {
        icon: rocket,
        title: "Accelerate your case resolution time by",
        estimate: "80%",
        paragraph:
          "By delivering instant, AI-powered insights from audio, video, and documents, Vollee accelerates investigations and case preparation, helping organizations close cases and make decisions significantly faster.",
        image: Scientist
      },
      {
        icon: target,
        title: "Reduce Time-to-Insight by",
        estimate: "95%",
        paragraph:
          "With advanced AI-driven scoring, workloads are reduced drastically. Whether for skilling, employee reviews, or operational audits, tasks that once took weeks are now completed in a fraction of the time—enabling faster, smarter decisions.",
        image: Safety
      },
      {
        icon: timer,
        title: "Speed up your search queries by",
        estimate: "10X",
        paragraph:
          "Unified search technology allows users to retrieve key insights from audio, video, and text-based data in seconds, streamlining evidence discovery and document analysis across large datasets.",
        image: education
      }
    ]
  },
  UseCase: {
    title: {
      title: "Use Case Scenarios"
    },
    content: [
      {
        title: "Transform education",
        paragraph: "with AI-empowered learning",
        link: "/education",
        image: caseEducating
      },
      {
        title: "Enhance safety & justice",
        paragraph: "with AI-driven insights",
        link: "/public-safety",
        image: caseSafety
      },
      {
        title: "Maximize productivity",
        paragraph: "with AI-powered solutions",
        link: "/productivity",
        image: caseProductivity
      }
    ]
  },
  Insights: {
    title: "Insights",
    content: [
      {
        title: "British Council",
        subTitle:
          "Vollee enhancing language assessment with AI-powered speech analysis",
        paragraph: [
          "<b>Description:</b> The British Council partnered with Vollee to assess English-speaking skills of students across various regions. Leveraging Vollee’s AI-driven Speech Assessment tool, the project utilized a customized rubric to evaluate pronunciation, fluency, and language proficiency with consistent and scalable results.",
          "<b>Outcome:</b> Vollee enabled the British Council to assess student speaking abilities at scale, ensuring objective evaluations and reducing manual assessment time by 75%. The AI-powered solution delivered highly accurate feedback, helping to streamline the grading process and enhance the overall learning experience."
        ],
        image: imgPublic,
        logo: britishCouncil
      },
      {
        title: "UAE",
        subTitle:
          "Revolutionizing Essay Evaluation with AI for the Ministry of Education",
        paragraph: [
          "<b>Description:</b> The Ministry of Education turned to Vollee to enhance its essay evaluation process. Using fine-tuned machine learning models, Vollee delivered unbiased, transparent, and highly accurate assessments. The AI system was tailored to the Ministry's specific grading standards, providing a scalable solution that eliminated human bias and greatly improved efficiency.",
          "<b>Outcome:</b> Vollee’s AI-powered solution allowed the Ministry to evaluate a significantly higher volume of essays in the same amount of time, with 99% accuracy. This cost-effective approach reduced manual effort and ensured consistent, bias-free feedback across all student submissions."
        ],
        image: imgPublic,
        logo: UAE
      },
      {
        title: "UFL",
        subTitle: "Scaling Personalized Assessments for Learners with AI",
        paragraph: [
          "<b>Description:</b> In collaboration with the University of Florida’s Lastinger Center for Learning, Vollee implemented an AI-powered system to assess learners across the state. Vollee acted as an automated assessor, providing personalized feedback while human assessors served as human-in-the-loop to ensure quality. This hybrid approach allowed learners to work at their own pace while benefiting from safe, secure, and fine-tuned explainable AI evaluations. The system also significantly improved the efficiency and accuracy of human assessors.",
          "<b>Outcome:</b> With Vollee’s solution, human-in-the-loop assessors saw their efficiency increase dramatically, enabling faster, more consistent feedback for learners. Learners now benefit from quicker assessments and shorter wait times for personalized feedback, allowing them to implement instructional improvements without delay. This approach has expanded the program’s capacity while maintaining high-quality, personalized support."
        ],
        image: imgPublic,
        logo: britishCouncil
      },
      {
        title: "Babson",
        subTitle:
          "Enhancing Business Analytics Education with AI-Powered MathBot at Babson College",
        paragraph: [
          "<b>Description:</b> Babson College partnered with Vollee to develop the MathBot, an AI-driven solution designed to assist students in navigating probability and statistics course content with precision. Delivered in under six weeks, the MathBot leverages Vollee’s powerful AI platform to offer personalized tutoring and evaluations, helping students grasp complex mathematical concepts and translate them into business language.",
          "<b>Outcome:</b> By incorporating Vollee’s AI Engine, Babson College has revolutionized the learning experience for its business analytics students. The AI-powered solution not only enhances students' understanding of quantitative topics but also equips them with the skills to communicate complex mathematical results effectively. The MathBot has ensured both academic integrity and domain-specific accuracy, leading to improved student outcomes and a more engaged learning environment."
        ],
        image: imgPublic,
        logo: UAE
      },
      {
        title: "RCSI",
        subTitle: "AI-Powered Video Analytics for Medical Education at RCSI",
        paragraph: [
          "<b>Description:</b> RCSI partnered with Vollee to implement a video analytics solution that evaluates medical students during their practical learning curriculum. The AI system analyzes student-patient interactions and provides detailed feedback based on a customized rubric tailored for medical education. Vollee’s solution ensures that each student receives accurate, timely assessments of their performance, helping them improve essential clinical skills.",
          "<b>Outcome:</b> Vollee’s video analytics system transformed the evaluation process at RCSI, allowing for a more objective and consistent assessment of medical students' practical interactions with patients. The rubric-based feedback enhanced the learning experience by providing students with actionable insights, leading to more effective skill development and better preparedness for real-world medical scenarios."
        ],
        image: imgPublic,
        logo: britishCouncil
      },
      {
        title: "Arlington PD, Washington",
        subTitle:
          "How a Washington Police Chief is Revolutionizing Investigations with AI-Powered Cell Phone Record Analysis",
        paragraph: [
          "<b>Description:</b> Vollee’s advanced AI engine was deployed to assist with investigations by analyzing cell phone records while adhering to CJIS cloud and personnel requirements. The solution leveraged geospatial analysis to pinpoint suspect locations and narrow down numbers, making the investigation process more efficient. With an easy-to-use interface, detectives were able to interact with and navigate the data seamlessly, accelerating case progress.",
          "<b>Outcome:</b> Vollee’s AI-driven solution streamlined the cell phone record analysis process, enabling detectives to quickly identify and narrow down potential suspects. By integrating geospatial analysis and providing a user-friendly interface, the system reduced manual effort and enhanced the speed and accuracy of investigations. The platform's compliance with CJIS requirements ensured that sensitive data was handled securely and in accordance with legal standards, leading to more effective and timely resolutions."
        ],
        image: imgPublic,
        logo: UAE
      },
      {
        title: "Alliance PD, Nebraska",
        subTitle:
          "Harnessing Bodycam Analysis and Predictive Policing with AI to Reduce Crime",
        paragraph: [
          "<b>Description:</b> A police department in Nebraska turned to Vollee’s AI technology to streamline bodycam footage analysis and enhance predictive policing efforts. By leveraging Vollee, bodycam footage is uploaded to the cloud within minutes, where the software automatically transcribes the footage into written reports. Officers can then copy and paste the transcript directly into their reports, reducing manual effort and saving time. This approach allows officers to focus on more critical aspects of policing while the technology handles the data-heavy work.",
          "<b>Outcome:</b> With Vollee’s automated bodycam analysis, officers can generate accurate reports in a fraction of the time, leading to increased efficiency across the department. Additionally, predictive policing has helped reduce crime in the community by enabling faster response times and more proactive interventions. Vollee’s AI has empowered officers to do what they do best—serve and protect—while letting the technology handle the laborious data processing tasks."
        ],
        image: imgPublic,
        logo: britishCouncil
      },
      {
        title: "MTPD",
        subTitle:
          "Harnessing AI to Tackle Cold Cases: The 1991 Cape May County Investigation",
        paragraph: [
          "<b>Description:</b> A police department in New Jersey State, in partnership with Vollee and Microsoft, is utilizing advanced AI technology to help solve a decades-old cold case from 1991 in Cape May County. Vollee’s powerful AI tools are being deployed to reanalyze evidence, examine historical records, and look for new connections in hopes of bringing resolution to the case of missing person Mark. This pilot program represents the future of cold case investigations, aiming to leverage AI’s capabilities for finding answers in cases that have long gone unsolved.",
          "<b>Outcome:</b> While the results are yet to be seen, Middle Township PD remains optimistic that Vollee’s AI-powered solution will help uncover new leads in this cold case. The department hopes that this pilot program will serve as a model for future cold case investigations, helping law enforcement agencies use advanced technology to solve even the most difficult cases."
        ],
        image: imgPublic,
        logo: UAE
      },
      {
        title: "Microsoft",
        subTitle:
          "Vollee: A Game-Changer for Law Enforcement and Public Safety",
        paragraph: [
          "<b>Description:</b> Vollee was highlighted as a key partner during Microsoft’s keynote at the IACP 2023 conference, where its impact on public safety and justice operations was showcased. Vollee has been successfully integrated into law enforcement agencies, enabling them to modernize their processes through advanced AI-driven solutions. From bodycam analysis and geospatial correlation to predictive policing and evidence review, Vollee is streamlining investigative work and improving efficiency across the board.",
          "<b>Outcome:</b> With Vollee’s AI-powered solutions, law enforcement agencies have seen significant improvements in their ability to analyze data and make quicker, more informed decisions. By automating time-consuming tasks such as transcription, evidence analysis, and correlation, Vollee allows officers to focus on their core responsibilities, reducing manual labor and increasing overall productivity. The technology has been especially impactful in helping departments adhere to strict security standards such as CJIS compliance while providing the tools necessary to tackle complex cases."
        ],
        image: imgPublic,
        logo: britishCouncil
      },
      {
        title: "WPS 2024",
        subTitle:
          "Pioneering AI for Real-Time Video Analysis in the Middle East at the World Police Summit 2024",
        paragraph: [
          "<b>Description:</b> At the 2024 World Police Summit in Dubai, Vollee collaborated with multiple law enforcement agencies across the Middle East to provide real-time video analysis and intelligent insights. Vollee’s advanced AI capabilities offered analysis in both English and Arabic, enabling seamless integration across diverse regions. This groundbreaking collaboration showcased the potential for AI to revolutionize police operations and deliver more efficient, accurate results in real-time.",
          "<b>Outcome:</b> Vollee’s performance at the summit drew special attention from Commander in Chief of Police Abdullah Khalifa Al Marri, who expressed interest in exploring Vollee's use within the department as an advanced AI service. The summit further cemented Vollee’s role as a leader in AI-driven policing, opening up opportunities for collaboration with police departments across the Middle East. With its ability to provide instant insights, Vollee is positioning itself as a critical tool for improving operational efficiency, language adaptability, and responsiveness in law enforcement."
        ],
        image: imgPublic,
        logo: UAE
      }
      // {
      //   title: "aaa",
      //   subTitle: "zzz",
      //   paragraph: ["<b>Description:</b> zzz", "<b>Outcome:</b> zzz"],
      //   image: imgPublic,
      //   logo: britishCouncil
      // }
    ]
  },
  Productivity: {
    banner: {
      subTitle: "USE CASE SCENARIOS",
      title: "Maximize Productivity",
      image: productivityMain,
      content: {
        title: "Supercharge Effort into RESULTS",
        paragraph:
          "Vollee’s suite of productivity services empowers organizations to streamline processes, extract insights, and make faster decisions using advanced AI technology. Our tools are adaptable to a wide range of industries, helping teams operate smarter."
      }
    },
    content: [
      {
        title: "Smart Transcription",
        paragraph:
          "Turn meetings, calls, audio or video recordings into accurate, searchable text, saving time on note-taking and information retrieval.",
        image: productivity1
      },
      {
        title: "Targeted Summarization",
        paragraph:
          "Generate concise summaries from long-form documents, meetings, or videos, allowing teams to focus on key information.",
        image: productivity2
      },
      {
        title: "Precision Search",
        paragraph:
          "Instantly locate relevant content and objects within audio, video, and documents, increasing productivity by eliminating manual search time.",
        image: productivity3
      },
      {
        title: "Conversation Agent",
        paragraph:
          "Interact with an automated bot powered by secure, guard-railed data, providing instant results complete with citations for accuracy and reliability.",
        image: productivity4
      },
      {
        title: "File Insights",
        paragraph:
          "Analyze documents, video, audio and images to identify keywords, entities, and important data points, supporting faster decision-making across business functions.",
        image: productivity5
      },
      {
        title: "Social Media Intelligence",
        paragraph:
          "Track and analyze social media data, providing actionable insights to analyze information, improve customer engagement.",
        image: productivity6
      }
    ]
  },
  publicSafety: {
    banner: {
      subTitle: "USE CASE SCENARIOS",
      title: "Enhance Safety & Justice",
      image: safetyMain,
      content: {
        title: "Convert Information into IMPACT",
        paragraph:
          "Vollee’s AI solutions help law enforcement, public safety agencies, and legal professionals streamline operations and enhance safety with fast, accurate data analysis. From real-time transcription to predictive policing, our tools empower teams to respond more effectively."
      }
    },
    content: [
      {
        title: "Speech and Video Transcription",
        paragraph:
          "Convert audio from interrogations, emergency calls, interviews, or video footage into actionable text, streamlining case documentation and improving accessibility for detailed analysis.",
        image: safety1
      },
      {
        title: "Unified Search",
        paragraph:
          "Leverage AI-powered search across audio, video, and document evidence to instantly find specific spoken words, objects, or text within files. Streamline investigations with comprehensive search capabilities that enhance situational awareness and accelerate case resolution.",
        image: safety2
      },
      {
        title: "Digital Evidence Analysis",
        paragraph:
          "Analyze video, audio, and digital evidence to support both active and cold case investigations. Uncover hidden connections with advanced relationship analysis, map plotting, and automated data correlation, reducing manual workload and accelerating case resolution.",
        image: safety3
      },
      {
        title: "Predictive Policing",
        paragraph:
          "Leverage AI to detect patterns and predict high-risk areas, enabling greater agility in incident anticipation and response, allowing for proactive interventions and optimizing resource allocation.",
        image: safety4
      },
      {
        title: "Training & Skilling",
        paragraph:
          "Equip officers and staff with AI-based training modules, enhancing their skills and knowledge with interactive learning experiences, violation detection and rubric-based assessments.",
        image: safety5
      },
      {
        title: "Correlation & Anomaly Detection",
        paragraph:
          "Uncover hidden patterns and irregularities in behavior or data by correlating information across multiple sources. Detect fraud, suspicious activity, or deviations from expected norms with AI-powered analysis, providing early warnings and insights to prevent potential issues.",
        image: safety6
      },
      {
        title: "Records Analysis & Discovery",
        paragraph:
          "Automate the search and categorization of documents, emails, and attachments, delivering precise datasets that match your query. Streamline discovery with AI-driven insights for faster case preparation.",
        image: safety7
      },
      {
        title: "Chat Assistant",
        paragraph:
          "Interact with an AI-powered assistant to ask questions and receive instant, data-driven answers with citations. Query video, audio, documents, and other evidence, enabling seamless interaction with complex datasets to retrieve specific information for evidence analysis and case preparation.",
        image: safety8
      }
    ]
  },
  Education: {
    banner: {
      subTitle: "Use Case Scenarios",
      title: "Transform Education",
      image: educationMain,
      content: {
        title: "Catapult Learning into ACHIEVEMENT",
        paragraph:
          "Vollee’s AI tools enhance learning experiences, streamline evaluation processes, and assist educators in delivering high-quality content. From automating grading to creating immersive learning environments, our solutions make education more accessible and effective."
      }
    },
    content: [
      {
        title: "Evaluation & Grading Assessment",
        paragraph:
          "Use AI to evaluate and grade assignments, whether they are documents, videos, or audio submissions, ensuring consistent and accurate feedback.",
        image: edu1
      },
      {
        title: "Immersive Learning",
        paragraph:
          "Create dynamic, engaging learning environments using AI to simulate real-world scenarios and personalize student experiences.",
        image: edu2
      },
      {
        title: "Content Creation & Curation",
        paragraph:
          "Empower educators with AI tools for content creation, helping them curate personalized lesson plans, assignments, and resources tailored to student needs.",
        image: edu3
      },
      {
        title: "Learning Assistant",
        paragraph:
          "Navigate and accelerate learning through a ChatGPT like experience leveraging ring-fenced and authenticated course content.",
        image: edu4
      },
      {
        title: "Research Intelligence",
        paragraph:
          "Accelerate research outcomes by obtaining instant insights across research fields supported by citations to drive innovation and collaboration.",
        image: edu5
      }
    ]
  },
  testimonials: {
    title: {
      title: "Testimonials",
      subTitle: "What People Say About Us"
    },
    testimonialsdetails: [
      {
        content:
          "Vollee allows us to let computers do computer work and humans do human work. With bodycam footage automatically transcribed and ready to paste into reports within minutes, we've not only saved time but also improved accuracy in our reporting. This has been a game-changer in our efforts to reduce crime through predictive policing.",
        author: "Chief Philip Lukens",
        authordesignation: "Chief of Police",
        authorOrganization: {
          name: "Alliance PD, Nebraska",
          logo: alliance
        }
      },
      {
        content:
          "Before Vollee, our assessors took weeks to review hundreds of documents. Now, with the Al system and human oversight, that same process takes only a few hours. This has not only revolutionized the way we assess, but also allows our learners to get faster feedback, helping them improve more quickly and effectively.",
        author: "Cathy Cavanaugh",
        authordesignation: "Assistant Director",
        authordesignation2: "Lastinger Center for Learning",
        authorOrganization: {
          name: "University of Florida",
          logo: universityofflorida
        }
      },
      {
        content:
          "Vollee's AI-powered cell phone record analysis solution has transformed the way we conduct investigations. Its geospatial analysis capabilities and intuitive interface allow us to identify suspects faster and more accurately, all while maintaining the highest standards of security and compliance with CJIS regulations.",
        author: "Chief Jonathan Ventura",
        authordesignation: "Chief of Police",
        authorOrganization: {
          name: "Arlington PD, Washington",
          logo: arlington
        }
      },

      {
        content:
          "Vollee's AI-powered speech analysis is setting new standards in language assessment. The significant reduction in manual effort, combined with highly accurate feedback, is a game-changer in creating more efficient and impactful learning experiences for students worldwide.",
        author: "Kevin Marshall",
        authordesignation: "Head of Future Skilling",
        authorOrganization: {
          name: "Microsoft Ireland",
          logo: mslogo
        }
      },
      {
        content:
          "I want to thank the Vollee team and Microsoft for partnering with us on this important project. It is important that we use every available resource and the latest technology to help us find Mark. My hope is this pilot program will help us solve Mark's case and be used as a model to help with other cold cases.",
        author: "Chief Christopher Leusner",
        authordesignation: "Chief of Police",
        authorOrganization: {
          name: "Middle Township PD, New Jersey",
          logo: middletownpolice
        }
      },

      {
        content:
          "Vollee is truly a game-changer. If you don't have this in your agency, you're doing things the old way... I retired two years ago. If I'd had this two years ago, I'd have been leaping for joy.",
        author: "Charlie Schaeffer",
        authordesignation: "Public Safety & Justice",
        authordesignation2: "Strategy Lead at Microsoft",
        authorOrganization: {
          name: "Retired FDLE CJIS Director",
          logo: mslogo
        }
      },
      {
        content:
          "Vollee's AI-enabled essay analysis has completely transformed our evaluation process. The accuracy, transparency, and efficiency have surpassed our expectations, allowing us to assess far more essays without compromising quality.",
        author: "Paul Jaquith",
        authordesignation: "Ministry of Education",
        authorOrganization: {
          name: "UAE",
          logo: uae
        }
      }
    ]
  },
  customerSlide: {
    title: {
      title: "Customers"
    },
    content: [
      {
        image: britishCouncilL,
        logo: img1,
        param: "british-council",
        title:
          "Vollee enhancing language assessment with AI-powered speech analysis",
        paragraph: [
          "The British Council partnered with Vollee to assess English-speaking skills of students across various regions. Leveraging Vollee’s AI-driven Speech Assessment tool, the project utilized a customized rubric to evaluate pronunciation, fluency, and language proficiency with consistent and scalable results.",
          "Vollee enabled the British Council to assess student speaking abilities at scale, ensuring objective evaluations and reducing manual assessment time by 75%. The AI-powered solution delivered highly accurate feedback, helping to streamline the grading process and enhance the overall learning experience.",
          "<span class='quotes'>“</span>",
          "<i>Vollee's AI-powered speech analysis is setting new standards in language assessment. The significant reduction in manual effort, combined with highly accurate feedback, is a game-changer in creating more efficient and impactful learning experiences for students worldwide</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),
          "<span class='quotesBy'> Kevin Marshall <br/> Head of Future Skilling, Microsoft Ireland</span>"
        ],
        link: "/customers/british-council"
      },
      {
        image: arlingtonL,
        logo: img2,
        param: "arlington",
        title:
          "How a Washington Police Chief is Revolutionizing Investigations with AI-Powered Cell Phone Record Analysis",
        paragraph: [
          "Vollee’s advanced AI engine was deployed to assist with investigations by analyzing cell phone records while adhering to CJIS cloud and personnel requirements. The solution leveraged geospatial analysis to pinpoint suspect locations and narrow down numbers, making the investigation process more efficient. With an easy-to-use interface, detectives were able to interact with and navigate the data seamlessly, accelerating case progress.",
          "Vollee’s AI-driven solution streamlined the cell phone record analysis process, enabling detectives to quickly identify and narrow down potential suspects. By integrating geospatial analysis and providing a user-friendly interface, the system reduced manual effort and enhanced the speed and accuracy of investigations. The platform's compliance with CJIS requirements ensured that sensitive data was handled securely and in accordance with legal standards, leading to more effective and timely resolutions.",
          "<span class='quotes'>“</span>",
          "<i>Vollee’s AI-powered cell phone record analysis solution has transformed the way we conduct investigations. Its geospatial analysis capabilities and intuitive interface allow us to identify suspects faster and more accurately, all while maintaining the highest standards of security and compliance with CJIS regulations.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),
          "<span class='quotesBy'> Chief Jonathan Ventura<br/> Arlington PD, Washington</span>"
        ],
        link: "/customers/arlington"
      },
      {
        image: babson,
        logo: img3,
        param: "babson",
        title:
          "Enhancing Business Analytics Education with AI-Powered MathBot at Babson College",
        paragraph: [
          "Babson College partnered with Vollee to develop the MathBot, an AI-driven solution designed to assist students in navigating probability and statistics course content with precision. Delivered in under six weeks, the MathBot leverages Vollee’s powerful AI platform to offer personalized tutoring and evaluations, helping students grasp complex mathematical concepts and translate them into business language.",
          "By incorporating Vollee’s AI Engine, Babson College has revolutionized the learning experience for its business analytics students. The AI-powered solution not only enhances students' understanding of quantitative topics but also equips them with the skills to communicate complex mathematical results effectively. The MathBot has ensured both academic integrity and domain-specific accuracy, leading to improved student outcomes and a more engaged learning environment.",
          "<span class='quotes'>“</span>",
          "<i>Our educators are constantly sourcing state-of-the-art tools to accelerate learning and ensure student success. The Babson MathBot, powered by Vollee, aids students with their probability and statistics courses. This innovative solution for active learning was delivered in under 6 weeks and is reshaping the way students learn business analytics.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),
          "<span class='quotesBy'> Patty Patria CIO <br/> Babson College</span>"
        ],
        link: "/customers/babson"
      },
      {
        image: allianceL,
        param: "alliance",
        logo: img4,
        title:
          "Harnessing Bodycam Analysis and Predictive Policing with AI to Reduce Crime",
        paragraph: [
          "A police department in Nebraska turned to Vollee’s AI technology to streamline bodycam footage analysis and enhance predictive policing efforts. By leveraging Vollee, bodycam footage is uploaded to the cloud within minutes, where the software automatically transcribes the footage into written reports. Officers can then copy and paste the transcript directly into their reports, reducing manual effort and saving time. This approach allows officers to focus on more critical aspects of policing while the technology handles the data-heavy work.",
          "With Vollee’s automated bodycam analysis, officers can generate accurate reports in a fraction of the time, leading to increased efficiency across the department. Additionally, predictive policing has helped reduce crime in the community by enabling faster response times and more proactive interventions. Vollee’s AI has empowered officers to do what they do best—serve and protect—while letting the technology handle the laborious data processing tasks.",
          "<span class='quotes'>“</span>",
          "<i>Vollee allows us to let computers do computer work and humans do human work. With bodycam footage automatically transcribed and ready to paste into reports within minutes, we’ve not only saved time but also improved accuracy in our reporting. This has been a game-changer in our efforts to reduce crime through predictive policing.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),
          "<span class='quotesBy'> Chief Philip Lukens <br/> Alliance PD, Nebraska</span>"
        ],
        link: "/customers/alliance"
      },
      {
        image: rsci,
        logo: img5,
        param: "rcsi",
        title: "AI-Powered Video Analytics for Medical Education at RCSI",
        paragraph: [
          "RCSI partnered with Vollee to implement a video analytics solution that evaluates medical students during their practical learning curriculum. The AI system analyzes student-patient interactions and provides detailed feedback based on a customized rubric tailored for medical education. Vollee’s solution ensures that each student receives accurate, timely assessments of their performance, helping them improve essential clinical skills.",
          "Vollee’s video analytics system transformed the evaluation process at RCSI, allowing for a more objective and consistent assessment of medical students' practical interactions with patients. The rubric-based feedback enhanced the learning experience by providing students with actionable insights, leading to more effective skill development and better preparedness for real-world medical scenarios."
        ],
        link: "/customers/rcsi"
      },
      {
        image: UFL,
        logo: img6,
        param: "ufl",
        title: "Scaling personalized assessments for learners with AI",
        paragraph: [
          "In collaboration with the University of Florida’s Lastinger Center for Learning, Vollee implemented an AI-powered system to assess learners across the state. Vollee acted as an automated assessor, providing personalized feedback while human assessors served as human-in-the-loop to ensure quality. This hybrid approach allowed learners to work at their own pace while benefiting from safe, secure, and fine-tuned explainable AI evaluations. The system also significantly improved the efficiency and accuracy of human assessors.",
          "With Vollee’s solution, human-in-the-loop assessors saw their efficiency increase dramatically, enabling faster, more consistent feedback for learners. Learners now benefit from quicker assessments and shorter wait times for personalized feedback, allowing them to implement instructional improvements without delay. This approach has expanded the program’s capacity while maintaining high-quality, personalized support.",
          "<span class='quotes'>“</span>",

          "<i>Before Vollee, our assessors took weeks to review hundreds of documents. Now, with the AI system and human oversight, that same process takes only a few hours. This has not only revolutionized the way we assess, but also allows our learners to get faster feedback, helping them improve more quickly and effectively.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),

          "<span class='quotesBy'> Cathy Cavanaugh <br/> Assistant Director, Lastinger Center for Learning at the University of Florida</span>"
        ],
        link: "/customers/ufl"
      },
      {
        image: mtpd,
        logo: img7,
        param: "mtpd",
        title:
          "Harnessing AI to Tackle Cold Cases: The 1991 Cape May County Investigation",
        paragraph: [
          "A police department in New Jersey State, in partnership with Vollee and Microsoft, is utilizing advanced AI technology to help solve a decades-old cold case from 1991 in Cape May County. Vollee’s powerful AI tools are being deployed to reanalyze evidence, examine historical records, and look for new connections in hopes of bringing resolution to the case of missing person Mark. This pilot program represents the future of cold case investigations, aiming to leverage AI’s capabilities for finding answers in cases that have long gone unsolved.",
          "While the results are yet to be seen, Middle Township PD remains optimistic that Vollee’s AI-powered solution will help uncover new leads in this cold case. The department hopes that this pilot program will serve as a model for future cold case investigations, helping law enforcement agencies use advanced technology to solve even the most difficult cases.",
          "<span class='quotes'>“</span>",
          "<i>I want to thank the Vollee team and Microsoft for partnering with us on this important project. It is important that we use every available resource and the latest technology to help us find Mark. My hope is this pilot program will help us solve Mark's case and be used as a model to help with other cold cases.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),

          "<span class='quotesBy'> Chief Christopher Leusner <br/> Middle Township PD, New Jersey</span>"
        ],
        link: "/customers/mtpd"
      },
      {
        image: UAEL,
        logo: img8,
        param: "uae",
        title:
          "Revolutionizing Essay Evaluation with AI for the Ministry of Education",
        paragraph: [
          "The Ministry of Education turned to Vollee to enhance its essay evaluation process. Using fine-tuned machine learning models, Vollee delivered unbiased, transparent, and highly accurate assessments. The AI system was tailored to the Ministry's specific grading standards, providing a scalable solution that eliminated human bias and greatly improved efficiency.",
          "Vollee’s AI-powered solution allowed the Ministry to evaluate a significantly higher volume of essays in the same amount of time, with 99% accuracy. This cost-effective approach reduced manual effort and ensured consistent, bias-free feedback across all student submissions.",
          "<span class='quotes'>“</span>",
          "<i>Vollee’s AI-enabled essay analysis has completely transformed our evaluation process. The accuracy, transparency, and efficiency have surpassed our expectations, allowing us to assess far more essays without compromising quality.</i>".replace(
            "Vollee",
            "<span class='vollee'>Vollee</span>"
          ),

          "<span class='quotesBy'> Paul Jaquith <br/> Ministry of Education, UAE</span>"
        ],
        link: "/customers/uae"
      }
    ]
  }
};
