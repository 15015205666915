import { Box, Container } from "@mui/material";
import React from "react";
import CardStack from "./CardStack";
import { MainTitle } from "./UI/Title";

const Testimonials = ({ title, subTitle, cardsData }) => {
  return (
    <Container>
      <Box
        sx={{
          flexDirection: { xs: "column", md: "column", lg: "row" }
        }}
        display={"flex"}
        paddingTop={"50px"}
        paddingBottom={"50px"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <MainTitle subTitle={title} title={subTitle} />
        </Box>
        <Box
          display={"flex"}
          sx={{
            flexDirection: { xs: "column", md: "row" }
          }}
        >
          <CardStack cardsData={cardsData}></CardStack>
        </Box>
      </Box>
    </Container>
  );
};

export default Testimonials;
