import React, { useState, useEffect, useRef } from "react";
import "./CardStack.css"; // Import your CSS file
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon
} from "@mui/icons-material";

const CardStack = (props) => {
  const [cardArrays, setCardArrays] = useState([]);
  const [isPlaying, setIsPlaying] = useState(true); // State for autoplay toggle
  const intervalIdRef = useRef(null); // Ref to hold the interval ID
  let isAnimating = false; // Moved to outer scope to manage animation status across all clicks
  const mobileView = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setCardArrays(Array.from(document.querySelectorAll(".card")));

    // Start the interval initially
    startAutoSlide();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalIdRef.current);
  }, []);

  const startAutoSlide = () => {
    // Start an interval that triggers right arrow click every 3 seconds
    intervalIdRef.current = setInterval(() => {
      document.getElementById("right-arrow").click();
    }, 7000);
  };

  const resetInterval = () => {
    // Clear the existing interval and start a new one
    clearInterval(intervalIdRef.current);
    if (isPlaying) {
      startAutoSlide();
    }
  };

  const toggleAutoplay = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      clearInterval(intervalIdRef.current);
    } else {
      startAutoSlide();
    }
  };

  function moveFirstCardToBack() {
    const firstCard = cardArrays.shift(); // Remove the first card from the array
    cardArrays.push(firstCard); // Add it to the end of the array
  }

  function moveLastCardToFront() {
    const lastCard = cardArrays.pop(); // Remove the last card from the array
    cardArrays.unshift(lastCard); // Add it to the start of the array
  }

  function resetCardPositions() {
    cardArrays.forEach((card, index) => {
      card.style.zIndex = mobileView
        ? index >= 3
          ? 1
          : 3 - index
        : index >= 5
        ? 1
        : 5 - index;
      card.style.transform = `translateY(${
        mobileView
          ? index >= 3
            ? 40
            : index * 20
          : index >= 5
          ? 80
          : index * 20
      }px) translateX(${
        mobileView
          ? index >= 3
            ? 40
            : index * 20
          : index >= 5
          ? 80
          : index * 20
      }px) scale(${
        mobileView
          ? index >= 3
            ? 0.9
            : 1 - index * 0.05
          : index >= 5
          ? 0.8
          : 1 - index * 0.05
      })`;
    });
  }

  const handleRightClick = () => {
    if (!isAnimating) {
      isAnimating = true;
      const firstCard = cardArrays[0];
      const secondCard = cardArrays[1];

      firstCard.classList.add("animate-back");

      setTimeout(() => {
        secondCard.classList.add("animate-forward");
      }, 350); // Start forward animation halfway through back animation

      setTimeout(() => {
        firstCard.classList.remove("animate-back");
        secondCard.classList.remove("animate-forward");
        moveFirstCardToBack();
        resetCardPositions();
        isAnimating = false;
      }, 700);

      // Reset the interval on click
      resetInterval();
    }
  };

  const handleLeftClick = () => {
    if (!isAnimating) {
      isAnimating = true;
      const lastCard = cardArrays[cardArrays.length - 1];

      lastCard.style.zIndex = 6;
      lastCard.style.transform = "translateY(-50%) translateY(20px) scale(1)";

      setTimeout(() => {
        moveLastCardToFront();
        resetCardPositions();
        isAnimating = false;
      }, 400);
    }
    // Reset the interval on click
    resetInterval();
  };

  return (
    <>
      <div className="card-stack-main-container">
        <div className="card-stack">
          {props.cardsData.map((items, idx) => {
            return (
              <Box
                className="card"
                key={idx}
                sx={{
                  padding: {
                    xs: "20px 24px",
                    sm: "24px 28px",
                    md: "28px 32px",
                    xl: "40px 48px"
                  },
                  height: {
                    xs: "256px",
                    sm: "286px",
                    md: "320px",
                    xl: "360px"
                  }
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"100%"}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                        xl: "17px"
                      },
                      textAlign: "justify",
                      lineHeight: {
                        xs: "16px",
                        sm: "18px",
                        md: "20px",
                        xl: "24px"
                      }
                    }}
                    color={"#000"}
                    fontWeight={"500"}
                  >
                    {items.content}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      textAlign: "start",
                      marginTop: "auto"
                    }}
                  >
                    <Box>
                      <Typography
                        color={"#000"}
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                            xl: "17px"
                          },
                          lineHeight: {
                            xs: "16px",
                            sm: "18px",
                            md: "20px",
                            xl: "24px"
                          }
                        }}
                      >
                        {items.author}
                      </Typography>
                      <Typography
                        color={"#000"}
                        fontWeight={"500"}
                        sx={{
                          fontSize: {
                            xs: "8px",
                            sm: "10px",
                            md: "12px",
                            xl: "15px"
                          },
                          lineHeight: {
                            xs: "16px",
                            sm: "18px",
                            md: "20px",
                            xl: "24px"
                          }
                        }}
                      >
                        {items.authordesignation}
                      </Typography>
                      <Typography
                        color={"#000"}
                        fontWeight={"500"}
                        sx={{
                          fontSize: {
                            xs: "8px",
                            sm: "10px",
                            md: "12px",
                            xl: "15px"
                          },
                          lineHeight: {
                            xs: "16px",
                            sm: "18px",
                            md: "20px",
                            xl: "24px"
                          }
                        }}
                      >
                        {items.authordesignation2}
                      </Typography>
                      <Typography
                        color={"#000"}
                        fontWeight={"500"}
                        sx={{
                          fontSize: {
                            xs: "8px",
                            sm: "10px",
                            md: "12px",
                            xl: "15px"
                          },
                          lineHeight: {
                            xs: "16px",
                            sm: "18px",
                            md: "20px",
                            xl: "24px"
                          }
                        }}
                      >
                        {items.authorOrganization.name}
                      </Typography>
                    </Box>
                    <div className="card-org-logo-container">
                      <img
                        className="card-org-logo"
                        src={items.authorOrganization.logo}
                        alt={items.authorOrganization.name}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </div>
      </div>
      <Box className="arrows">
        <IconButton onClick={handleLeftClick} id="left-arrow">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "30px",
              minWidth: "30px"
            }}
          />
        </IconButton>
        <IconButton className={"arrowsAction"} onClick={toggleAutoplay}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton onClick={handleRightClick} id="right-arrow">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "30px",
              minWidth: "30px"
            }}
          />
        </IconButton>
      </Box>
    </>
  );
};

export default CardStack;
